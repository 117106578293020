body {
  background-color: rgb(20, 20, 20);
}

a {
  color: white;
  text-decoration-thickness: 0.1px;
}

.Home {
  height: calc(100vh - 20px);
  z-index: 1000;
}

.name-container {
  text-align: center;
  color: rgb(59, 59, 59);
  position: fixed;
  font-size: 90px;
  font-family: "Garamond";
  top: 44%;
  width: 100%;
  height: 90px;
}

.subtitle-container {
  text-align: center;
  color: rgb(59, 59, 59);
  position: fixed;
  font-size: 30px;
  font-family: "Garamond";
  top: calc(50% + 90px);
  width: calc(100% - 80px);
  margin-left: 40px;
  margin-right: 40px;
  height: 30px;
}

@media only screen and (max-width: 600px) {
  .name-container {
    font-size: 80px;
  }
}

.caption-container {
  text-align: center;
  color: white;
  position: fixed;
  bottom: calc(50% - 30px);
  font-family: "Garamond";
  /* width: 100px; */
  height: 50px;
  font-size: 30px;
  left: 50%;          /* Move to 50% of the viewport width */
  transform: translateX(-50%);
  text-decoration: underline;
  text-decoration-thickness: 0.1px;
  text-underline-offset: 5px;
}

@keyframes pulse {
  0%, 100% { color: #0e0e0e; } /* Darker color */
  50% { color: #5f5f5f; }      /* Lighter color */
}

.pulsing-text {
  animation: pulse 5s infinite; /* 2s is the duration of one cycle */
}

.fade-in {
  animation: fadeIn 1s; /* 1s is the duration of the fade in */
  opacity: 1;
}

.fade-out {
  animation: fadeOut 1s; /* 1s is the duration of the fade out */
  opacity: 0;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.bouncing-arrow {
  text-align: center;
  color: gray;
}

.arrow-up {
  transform: translateX(-50%);
  left: 50%;
  position: fixed;
  top: 20px;
}

.arrow-up:hover {
  color: white;
  cursor: pointer;
}

.arrow-down:hover {
  color: white;
  cursor: pointer;
}

.arrow-down {
  transform: translateX(-50%);
  left: 50%;
  position: fixed;
  bottom: 20px;
}

.bouncing-arrow span {
  display: inline-block;
  font-size: 2em; /* Adjust the size of the arrow */
  animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-20px);}
  60% {transform: translateY(-15px);}
}

.arrow-tip-up {
  position: relative;
  width: 30px;   /* Width of the arrow tip */
  height: 30px;  /* Height of the arrow tip */
  margin: 0 auto; /* Centering if needed */
}

.arrow-tip-up::before, .arrow-tip-up::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 1px; /* Line thickness */
  height: 100%;
  background-color: gray;
}

.arrow-tip-up::before {
  transform: rotate(45deg);
  left: 4px;
}

.arrow-tip-up::after {
  transform: rotate(-45deg);
  right: 4px;
}

.arrow-tip-down {
  position: relative;
  width: 30px;   /* Width of the arrow tip */
  height: 30px;  /* Height of the arrow tip */
  margin: 0 auto; /* Centering if needed */
}

.arrow-tip-down::before, .arrow-tip-down::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 1px; /* Line thickness */
  height: 100%;
  background-color: gray;
}

.arrow-tip-down::before {
  transform: rotate(-45deg);
  left: 4px;
}

.arrow-tip-down::after {
  transform: rotate(45deg);
  right: 4px;
}
