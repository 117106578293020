.nav-link {
    margin-right: 15px;
    color: gray;
    text-decoration-thickness: 0.1px;
    text-underline-offset: 5px;
}

* {
    font-family: "Garamond";
}

.nav-link:hover {
    color: white;
}